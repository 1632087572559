import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import MuiModal from "@mui/material/Modal";
import useTheme from "@mui/material/styles/useTheme";
import CloseIcon from "icons/close";
import CloseIconCircle from "icons/closeCircle";

function Modal(props) {
  const {
    children = null,
    title = "",
    actions = null,
    onClose,
    ...others
  } = props;

  const theme = useTheme();

  return (
    <MuiModal onClose={onClose} {...others}>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          background: "rgba(76,76,76,0.7)",
        }}
        onClick={onClose}
        role="presentation"
      >
        <Box
          sx={{
            position: "relative",
            margin: "40px 16px 0",
            maxHeight: "calc(100% - 80px)",
            overflow: "auto",
            borderRadius: "8px",
            [theme.breakpoints.up("md")]: {
              width: "620px",
            },
          }}
        >
          <Paper square onClick={(e) => e.stopPropagation()}>
            <Box
              sx={{
                padding: "20px",
                [theme.breakpoints.up("sm")]: {
                  padding: "24px 20px",
                },
                [theme.breakpoints.up("md")]: {
                  padding: "32px 40px 40px",
                },
              }}
            >
              <CloseIcon
                onClick={onClose}
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  cursor: "pointer",
                  fontSize: "24px",
                  [theme.breakpoints.up("md")]: {
                    display: "none",
                  },
                }}
              />
              <CloseIconCircle
                onClick={onClose}
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  cursor: "pointer",
                  fontSize: "40px",
                  display: "none",
                  [theme.breakpoints.up("md")]: {
                    display: "block",
                  },
                }}
              />
              {title && (
                <Box
                  sx={{
                    color: theme.palette["brand-02-blue-01-default"],
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 700,
                    marginBottom: "40px",
                    marginRight: "30px",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "22px",
                      lineHeight: "28px",
                    },
                  }}
                >
                  {title}
                </Box>
              )}
              {children}
            </Box>
            {actions && <div>{actions}</div>}
          </Paper>
        </Box>
      </Box>
    </MuiModal>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  actions: PropTypes.node,
};

export default Modal;
