import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="nonzero"
        d="M 12 0.191406 C 13.480469 0.191406 14.84375 1.015625 15.652344 2.390625 L 23.277344 15.386719 C 23.75 16.195312 24 17.132812 24 18.097656 C 24 20.828125 22.007812 23.050781 19.558594 23.050781 L 4.441406 23.050781 C 1.992188 23.050781 0 20.828125 0 18.097656 C 0 17.132812 0.25 16.195312 0.722656 15.386719 L 8.347656 2.390625 C 9.15625 1.011719 10.519531 0.191406 12 0.191406 Z M 12 16.191406 C 11.398438 16.191406 10.910156 16.703125 10.910156 17.335938 C 10.910156 17.964844 11.398438 18.476562 12 18.476562 C 12.601562 18.476562 13.089844 17.964844 13.089844 17.335938 C 13.089844 16.703125 12.601562 16.191406 12 16.191406 Z M 12 7.046875 C 11.367188 7.046875 10.863281 7.617188 10.917969 8.28125 L 10.925781 8.371094 L 11.398438 14.476562 C 11.398438 14.792969 11.667969 15.046875 12 15.046875 C 12.332031 15.046875 12.601562 14.792969 12.601562 14.476562 L 13.085938 8.28125 C 13.140625 7.617188 12.640625 7.046875 12 7.046875 Z M 12 7.046875 "
      />
    </SvgIcon>
  );
}

export default Icon;
