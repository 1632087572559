import React from "react";
import MuiTextField from "@mui/material/TextField";

function TextField(props) {
  return (
    <MuiTextField variant="outlined" color="secondary" fullWidth {...props} />
  );
}

export default TextField;
