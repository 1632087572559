import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import ArrowLinkIcon from "icons/arrowLink";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { currentLanguage, langueList } from "config/i18n";
import ExpandMoreIcon from "icons/expandMore";

function Footer({ onRefChange }) {
  const { i18n, t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const { pathname } = location;

  const [anchorChooseLanguage, setAnchorChooseLanguage] = useState(null);
  const [language, setLanguage] = useState(currentLanguage);

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
    setAnchorChooseLanguage(null);
  };

  const isAnchorChooseLanguageOpen = Boolean(anchorChooseLanguage);

  const {
    REACT_APP_CGU_URL,
    REACT_APP_FOOTER_ACCESSIBILITY_URL,
    REACT_APP_FOOTER_HELP_URL,
  } = process.env;

  const links = [
    {
      href: REACT_APP_FOOTER_HELP_URL,
      text: t("footer:link.help"),
    },
    {
      href: REACT_APP_CGU_URL,
      text: t("footer:link.cgu"),
      mobileText: "CGU",
    },
    {
      href: REACT_APP_FOOTER_ACCESSIBILITY_URL,
      text: t("footer:link.accessibility"),
    },
  ];

  return (
    <footer ref={onRefChange} role="contentinfo">
      <Menu
        id="open-choose-language-menu"
        sx={{
          "& > .MuiPaper-root": {
            background: "#555",
            color: "#fff",
            "& .Mui-selected": {
              ":hover": {
                background: "#717171",
              },
              background: "#717171",
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "open-choose-language",
        }}
        anchorEl={anchorChooseLanguage}
        getContentAnchorEl={null}
        open={isAnchorChooseLanguageOpen}
        onClose={() => setAnchorChooseLanguage(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            style: {
              marginTop: theme.spacing(-1.5),
            },
          },
        }}
      >
        {Object.keys(langueList).map((languageKey) => {
          const { name } = langueList[languageKey];
          return (
            <MenuItem
              key={name}
              onClick={() => changeLanguage(languageKey)}
              selected={languageKey === language}
              aria-label={name}
              lang={languageKey}
            >
              {name}
            </MenuItem>
          );
        })}
      </Menu>
      {pathname === "/" && (
        <Typography
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            p: theme.spacing(1, 2),
            fontSize: "0.675rem",
            lineHeight: "1.5",
            backgroundColor: theme.palette["background-light"],
            color: theme.palette["01-body-background-charte-gris-6-tex"],
            "& > a": {
              color: theme.palette["01-body-background-charte-gris-6-tex"],
            },
            [theme.breakpoints.up("sm")]: {
              fontSize: "0.75rem",
            },
            [theme.breakpoints.up("lg")]: {
              backgroundColor: "#262626",
              color: "#fff",
              "&& > a": {
                color: "#fff",
              },
            },
          }}
        >
          {t("footer:infos.legalMentions")}
          <a
            style={{
              wordBreak: "break-all",
            }}
            href="https://www.laposte.fr/professionnel/donnees-personnelles-et-cookies"
            target="_blank"
            rel="noreferrer"
          >
            l’usage de vos données
          </a>
          {t("footer:infos.and")}
          <a
            style={{
              wordBreak: "break-all",
            }}
            href="https://www.laposte.fr/professionnel/politique-de-protection-des-donnees"
            target="_blank"
            rel="noreferrer"
          >
            vos droits
          </a>
          .
        </Typography>
      )}
      <Grid
        container
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette["background-light"],
          color: theme.palette["01-body-background-charte-gris-6-tex"],
          [theme.breakpoints.up("lg")]: {
            backgroundColor: "#262626",
            color: "#fff",
          },
        }}
      >
        <Grid
          id="open-choose-language"
          item
          sx={{
            flex: "auto",
            textAlign: "center",
            fontSize: "10px",
            fontWeight: 500,
            lineHeight: "15px",
            padding: "13px 0",
            color: "inherit",
            "&:not(:last-child):after": {
              content: '"|"',
              float: "right",
              color: theme.palette.divider,
              [theme.breakpoints.up("lg")]: {
                paddingLeft: "40px",
              },
            },
            [theme.breakpoints.up("lg")]: {
              fontSize: "12px",
              flex: "none",
              paddingLeft: "40px",
              "&:last-child": {
                paddingRight: "40px",
              },
            },
            cursor: "pointer",
          }}
          role="button"
          tabIndex={0}
          aria-controls={
            isAnchorChooseLanguageOpen ? "open-choose-language-menu" : undefined
          }
          aria-haspopup="true"
          aria-expanded={isAnchorChooseLanguageOpen ? "true" : undefined}
          aria-label={t("footer:menuLanguage.chooseLanguage.ariaLabel")}
          onClick={(e) => {
            setAnchorChooseLanguage(e.currentTarget);
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              setAnchorChooseLanguage(e.currentTarget);
            }
          }}
        >
          <span>
            {isMobile
              ? langueList[language].mobileName
              : langueList[language].name}
            <ExpandMoreIcon
              sx={{
                fill: theme.palette["01-body-background-charte-gris-6-tex"],
                [theme.breakpoints.up("lg")]: {
                  fill: "#fff",
                },
                verticalAlign: "middle",
                marginLeft: theme.spacing(0.625),
              }}
            />
          </span>
        </Grid>
        {links.map((link) => {
          const { href, text, mobileText } = link;
          return (
            <Grid
              key={href}
              item
              sx={{
                flex: "auto",
                textAlign: "center",
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "15px",
                padding: "13px 0",
                color: "inherit",
                "&:not(:last-child):after": {
                  content: '"|"',
                  float: "right",
                  color: theme.palette.divider,
                  [theme.breakpoints.up("lg")]: {
                    paddingLeft: "40px",
                  },
                },
                [theme.breakpoints.up("lg")]: {
                  fontSize: "12px",
                  flex: "none",
                  paddingLeft: "40px",
                  "&:last-child": {
                    paddingRight: "40px",
                  },
                },
              }}
              component={Link}
              target="_blank"
              href={href}
              underline="none"
              color="inherit"
              aria-label={`${text}, ouverture vers un nouvel onglet`}
            >
              <span>{mobileText && isMobile ? mobileText : text}</span>
              <ArrowLinkIcon
                sx={{
                  width: "8px",
                  height: "8px",
                  marginLeft: theme.spacing(1),
                  fill: theme.palette["01-body-background-charte-gris-6-tex"],
                  [theme.breakpoints.up("lg")]: {
                    fill: "#fff",
                  },
                }}
                viewBox="0 0 10 10"
              />
            </Grid>
          );
        })}
      </Grid>
    </footer>
  );
}

Footer.propTypes = {
  onRefChange: PropTypes.func.isRequired,
};

export default Footer;
