import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

function Illustration(props) {
  const { children = null, className = null, ...others } = props;

  return (
    <Box
      mt={5}
      mb={3}
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      className={className}
      {...others}
    >
      {children}
    </Box>
  );
}

Illustration.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Illustration;
