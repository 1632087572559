import React from "react";
import MuiButton from "@mui/material/Button";

function Button(props) {
  return (
    <MuiButton
      color="primary"
      variant="contained"
      disableElevation
      fullWidth
      {...props}
    />
  );
}

export default Button;
