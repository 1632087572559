import React from "react";
import PropTypes from "prop-types";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import WarningIcon from "icons/warning";
import SuccessIcon from "icons/success";
import CloseIcon from "icons/close";
import InfoIcon from "icons/info";

const ErrorIcon = WarningIcon;

export const types = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
  INFO: "INFO",
};

const getBackgroundColorByType = (type, theme) => {
  switch (type) {
    case types.ERROR:
      return theme.palette["status-background-error-backgroun"];
    case types.WARNING:
      return theme.palette["status-background-warning-backgro"];
    case types.SUCCESS:
      return theme.palette["status-background-success-backgro"];
    case types.INFO:
      return theme.palette["status-background-information-bac"];
    default:
      return theme.palette["status-background-information-bac"];
  }
};

const getColorByType = (type, theme) => {
  switch (type) {
    case types.ERROR:
      return theme.palette["status-icon-error"];
    case types.WARNING:
      return theme.palette["status-icon-warning"];
    case types.SUCCESS:
      return theme.palette["status-icon-success"];
    case types.INFO:
    default:
      return theme.palette["status-icon-information"];
  }
};

const getIconByType = (type) => {
  switch (type) {
    case types.ERROR:
      return <ErrorIcon />;
    case types.WARNING:
      return <WarningIcon />;
    case types.SUCCESS:
      return <SuccessIcon />;
    case types.INFO:
    default:
      return <InfoIcon />;
  }
};

function Toaster(props) {
  const {
    type = types.INFO,
    children = null,
    onClose = null,
    className = null,
    sx = {},
    ...others
  } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "no-wrap",
        padding: "14px 12px 17px",
        backgroundColor: getBackgroundColorByType(type, theme),
        fontSize: "12px",
        borderRadius: "8px",
        [theme.breakpoints.up("sm")]: {
          paddingBottom: "14px",
        },
        ...sx,
      }}
      className={className}
      {...others}
    >
      <Box
        sx={{
          color: getColorByType(type, theme),
          fontSize: "18px",
          marginTop: "2px",
        }}
      >
        {getIconByType(type)}
      </Box>
      <Box
        sx={{
          flex: 1,
          marginLeft: "8px",
          color: theme.palette["background-dark"],
          lineHeight: "18px",
        }}
      >
        {children}
      </Box>
      {typeof onClose === "function" && (
        <Box sx={{ marginLeft: "8px", fontSize: "20px" }}>
          <CloseIcon onClick={onClose} sx={{ cursor: "pointer" }} />
        </Box>
      )}
    </Box>
  );
}

Toaster.propTypes = {
  type: PropTypes.oneOf(Object.values(types)),
  children: PropTypes.node,
  onClose: PropTypes.func,
  className: PropTypes.string,
  sx: PropTypes.shape(),
};

export default Toaster;
