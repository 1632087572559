import React, { useEffect } from "react";
import TC_Wrapper from "react-tag-commander";

export default (WrappedComponent, options = {}) => {
  const trackPage = () => {
    const wrapper = TC_Wrapper.getInstance();
    wrapper.removeContainer("container_body");

    const { tcVars, event, variables } = options;

    if (tcVars) {
      wrapper.setTcVars(tcVars);
    }

    wrapper.addContainer(
      "container_body",
      process.env.REACT_APP_TC_SCRIPT_URL,
      "body",
    );

    if (event) {
      wrapper.triggerEvent(event.label, event.context || this, variables || {});
    }
  };

  function HighOrderComponent(props) {
    useEffect(() => {
      trackPage();
    }, []);

    return React.createElement(WrappedComponent, props);
  }

  return HighOrderComponent;
};
