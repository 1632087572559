import React from "react";
import PropTypes from "prop-types";

function MainContent(props) {
  const { children = null, className = null, ...others } = props;

  return (
    <div style={{ flex: "1 auto" }} className={className} {...others}>
      {children}
    </div>
  );
}

MainContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default MainContent;
