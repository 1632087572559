import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function ArrowLinkIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M0.833333 8.22912L7.23636 1.82609H3.21101C2.84435 1.82609 2.54797 1.52971 2.54797 1.16305C2.54797 0.796381 2.84435 0.5 3.21101 0.5H9.5V6.78899C9.5 7.15565 9.20362 7.45203 8.83695 7.45203C8.47095 7.45203 8.17391 7.15565 8.17391 6.78899V2.76364L1.77088 9.16667L0.833333 8.22912Z" />
    </SvgIcon>
  );
}

export default ArrowLinkIcon;
