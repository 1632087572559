import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Redirect, useLocation } from "react-router-dom";
import { useSettings } from "components/Settings";
import Loader from "components/Loader";
import ServiceUnavailable from "pages/ServiceUnavailable";
import withTracker from "components/withTracker";
import { getTags } from "utils/TagUtil";
import UserService from "services/UserService";

function Route(props) {
  const {
    route,
    isPrivate = false,
    isTestMode = false,
    ...componentProps
  } = props;

  const location = useLocation();
  const { processEnabled = true } = useSettings();
  const { tcVars, component } = route;

  const userId = UserService.getUserId();

  const Component = isTestMode
    ? lazy(() => import(`pages/test_mode/${component}`))
    : lazy(() => import(`pages/${component}`));

  const renderComponent = useMemo(
    () => (
      <Suspense fallback={<Loader isLoading />}>
        <Component {...componentProps} />
      </Suspense>
    ),
    [componentProps],
  );
  const [render, setRender] = useState(renderComponent);

  useEffect(() => {
    if (isPrivate && !processEnabled) {
      setRender(<ServiceUnavailable />);
    }
  }, [isPrivate, processEnabled]);

  const tcVarsValues =
    typeof tcVars === "function" ? tcVars({ location }) : tcVars;
  const notFinalComponent =
    render && (render.type === Loader || render.type === Redirect);

  const RenderWithTracker = notFinalComponent
    ? () => render
    : withTracker(() => render, {
        tcVars: getTags({ ...tcVarsValues, userId }),
      });

  return <RenderWithTracker />;
}

Route.propTypes = {
  route: PropTypes.shape({
    path: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
    tcVars: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  }).isRequired,
  isTestMode: PropTypes.bool,
  isPrivate: PropTypes.bool,
};

export default Route;
