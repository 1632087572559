import React from "react";
import { Link as RouterLink } from "react-router-dom";

import MuiLink from "@mui/material/Link";

function Link(props) {
  return <MuiLink component={RouterLink} color="secondary" {...props} />;
}

export default Link;
