import React, { useCallback, useState } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import AuthCCUProvider from "components/AuthCCU/AuthCCUProvider";
import Link from "components/Link";
import Layout from "Layout";
import StyleGuide from "StyleGuide";
import UrlParametersKey from "utils/UrlParametersKey";
import RouteComponent from "components/Route";
import {
  COMMON_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
  TEST_MODE_ROUTES,
} from "utils/RouteUtils";
import {
  setIsTestMode,
  getIsTestMode,
  isTestModeAuthorized,
} from "utils/TestModeParametersUtil";
import Box from "@mui/material/Box";
import Button from "components/Button";

function Router() {
  const location = useLocation();
  const history = useHistory();
  const { search, pathname } = location;

  const [disconnected, setDisconnected] = useState(false);

  const isTestMode = getIsTestMode();

  const handleAuthError = useCallback(() => {
    setDisconnected(true);
  }, []);

  const entry = new URLSearchParams(search).get(UrlParametersKey.ENTRY);
  if (entry) {
    sessionStorage.setItem(UrlParametersKey.ENTRY, entry);
  }

  const TEST_MODE_ROUTES_PATHES = Object.values(TEST_MODE_ROUTES).map(
    (route) => route.path,
  );

  const handleTestMode = (testMode) => {
    setIsTestMode(testMode);
    sessionStorage.setItem("TEST_MODE", testMode ? "true" : "false");
  };

  return (
    <Layout>
      <Box
        sx={{
          position: "fixed",
          top: 7,
          right: 16,
        }}
      >
        {isTestModeAuthorized && (
          <>
            {isTestMode && !TEST_MODE_ROUTES_PATHES.includes(pathname) && (
              <Button
                style={{
                  backgroundColor: "#fff",
                  color: "#20458f",
                  borderRadius: "12px",
                  padding: "10px",
                  marginRight: "1rem",
                  height: "auto",
                  width: "fit-content",
                  border: "none",
                }}
                onClick={() => {
                  if (pathname === COMMON_ROUTES.SERVICE_UNAVAILABLE.path) {
                    history.replace(TEST_MODE_ROUTES.TEST_MODE_HOME.path);
                  }
                  history.go(0);
                }}
              >
                Faire un autre test
              </Button>
            )}
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#20458f",
                borderRadius: "12px",
                padding: "10px",
                marginRight: "1rem",
                height: "auto",
                width: "fit-content",
                border: "none",
              }}
              onClick={() => {
                if (isTestMode) {
                  handleTestMode(false);
                  if (pathname === COMMON_ROUTES.SERVICE_UNAVAILABLE.path) {
                    history.replace("/");
                  }
                  history.go(0);
                } else {
                  handleTestMode(true);
                  history.push(TEST_MODE_ROUTES.TEST_MODE_HOME.path);
                }
              }}
            >
              {isTestMode ? "Quitter Mode Test" : "Mode Test"}
            </Button>
          </>
        )}
        {process.env.NODE_ENV === "development" && (
          <Link
            to="/charte-graphique"
            style={{
              backgroundColor: "#fff",
              color: "#20458f",
              borderRadius: "12px",
              padding: "10px",
            }}
          >
            Charte graphique
          </Link>
        )}
      </Box>
      <Switch>
        {process.env.NODE_ENV === "development" && (
          <Route path="/charte-graphique" component={StyleGuide} exact />
        )}
        {Object.keys(COMMON_ROUTES).map((commonRoute) => {
          const route = COMMON_ROUTES[commonRoute];
          const { path } = route;

          return (
            <Route
              key={path}
              path={path}
              exact
              component={(props) => (
                <RouteComponent {...props} isCommon route={route} />
              )}
            />
          );
        })}
        {!disconnected && (
          <AuthCCUProvider
            onError={handleAuthError}
            data-testid="connectedHome"
          >
            {isTestModeAuthorized &&
              Object.keys(TEST_MODE_ROUTES).map((modeTestRoute) => {
                const route = TEST_MODE_ROUTES[modeTestRoute];
                const { path } = route;
                return (
                  <Route
                    key={path}
                    path={path}
                    exact
                    component={(props) => (
                      <RouteComponent
                        {...props}
                        isPrivate
                        isTestMode
                        route={route}
                      />
                    )}
                  />
                );
              })}
            {Object.keys(PRIVATE_ROUTES).map((privateRoute) => {
              const route = PRIVATE_ROUTES[privateRoute];
              const { path } = route;

              return (
                <Route
                  key={path}
                  path={path}
                  exact
                  component={(props) => (
                    <RouteComponent {...props} isPrivate route={route} />
                  )}
                />
              );
            })}
          </AuthCCUProvider>
        )}
        {disconnected &&
          Object.keys(PUBLIC_ROUTES).map((publicRoute) => {
            const route = PUBLIC_ROUTES[publicRoute];
            const { path } = route;

            return (
              <Route
                key={path}
                path={path}
                exact
                component={(props) => (
                  <RouteComponent {...props} isPrivate={false} route={route} />
                )}
              />
            );
          })}
      </Switch>
    </Layout>
  );
}

export default Router;
