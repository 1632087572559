import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useCallback } from "react";
import TokenService from "services/TokenService";
import { PUBLIC_ROUTES } from "utils/RouteUtils";

function AuthCCURedirect(props) {
  const { onSuccess, onError } = props;

  const location = useLocation();
  const history = useHistory();

  const { search } = location;

  const handleAuthError = useCallback(() => {
    onError();
    history.push(PUBLIC_ROUTES.HOME_DISCONNECTED.path);
  }, [history, onError]);

  useEffect(() => {
    const code = new URLSearchParams(search).get("code");
    const state = new URLSearchParams(search).get("state");
    const storedState = sessionStorage.getItem("_stateStorage");

    sessionStorage.removeItem("_stateStorage");

    if (state !== storedState) {
      handleAuthError();
    } else if (!code) {
      handleAuthError();
    } else {
      TokenService.getTokenFromCode(code)
        .then((response) => {
          if (response) {
            onSuccess(response.data);
          } else {
            handleAuthError();
          }
        })
        .catch(() => {
          handleAuthError();
        });
    }
  }, [search, onError, onSuccess, handleAuthError]);

  return null;
}

AuthCCURedirect.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AuthCCURedirect;
