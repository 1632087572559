import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

function MiniCard(props) {
  const {
    children = null,
    className = null,
    type = "tertiary",
    onClick = null,
    ...others
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginBottom: theme.spacing(2),
        borderRadius: "8px",
        cursor: "pointer",
        fontSize: 14,
        padding: "12px 16px 12px 18px",
        ...(type === "primary" && {
          background: theme.palette["brand-02-blue-01-default"],
          color: theme.palette["background-light"],
          "& p": {
            color: theme.palette["background-light"],
            fontSize: 14,
          },
        }),
        ...(type === "secondary" && {
          background: "#f0f6ff",
          color: theme.palette["01-body-background-charte-gris-6-tex"],
          "& p": {
            color: theme.palette["01-body-background-charte-gris-6-tex"],
            fontSize: 14,
          },
        }),
        ...(type === "tertiary" && {
          background: theme.palette["background-light"],
          border: `1px solid ${theme.palette["neutral-neutral-3"]}`,
          color: "#363636",
          "& p": {
            color: "#363636",
            fontSize: 14,
          },
        }),
      }}
      className={className}
      onClick={onClick}
      {...others}
    >
      {children}
    </Box>
  );
}
MiniCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  onClick: PropTypes.func,
};

export default MiniCard;
