import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Box } from "@mui/material";
import Link from "components/Link";
import useTheme from "@mui/material/styles/useTheme";
import ArrowIcon from "icons/arrowleft";
import { useTranslation } from "react-i18next";
import { NEXT_PAGE } from "utils/RouteUtils";
import { getIsTestMode } from "utils/TestModeParametersUtil";

function Step(props) {
  const {
    progress,
    title = null,
    previousStepPath = null,
    children = null,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const isTestMode = getIsTestMode();

  return (
    <>
      <Grid container direction="row" alignItems="center">
        {(Boolean(previousStepPath) && (
          <Grid
            item
            component={Link}
            to={{ pathname: previousStepPath, state: { from: NEXT_PAGE } }}
            color="inherit"
            sx={{
              width: 24,
              height: 24,
              marginRight: theme.spacing(2),
              color: "inherit",
              "& svg": {
                width: "24px",
                height: "24px",
              },
              [theme.breakpoints.up("md")]: {
                width: 32,
                height: 32,
                "& svg": {
                  width: "32px",
                  height: "32px",
                },
              },
              [theme.breakpoints.up("lg")]: {
                width: 24,
                height: 24,
                "& svg": {
                  width: "24px",
                  height: "24px",
                },
              },
            }}
            aria-label="Retour"
          >
            <ArrowIcon />
          </Grid>
        )) ||
          null}
        <Typography variant="h1">
          {isTestMode ? t("step:testTitle") : t("step:title")}
        </Typography>
        {(Boolean(previousStepPath) && <Grid item />) || null}
      </Grid>
      <Box
        sx={{
          marginTop: "12px",
          height: "4px",
          backgroundColor: theme.palette["neutral-neutral-3"],
          borderRadius: "2px",
          "&:before": {
            content: "''",
            display: "block",
            height: "100%",
            width: `${progress}%`,
            backgroundColor: theme.palette.primary.main,
            borderRadius: "2px",
          },
        }}
      />
      {(Boolean(title) && (
        <Typography
          variant="h2"
          component={Grid}
          container
          wrap="nowrap"
          spacing={1}
          sx={{
            maxWidth: "100%",
            marginTop: "24px",
            marginBottom: "16px",
            [theme.breakpoints.up("sm")]: {
              marginBottom: "24px",
            },
            [theme.breakpoints.up("md")]: {
              marginTop: "40px",
            },
            [theme.breakpoints.up("lg")]: {
              marginTop: "24px",
            },
          }}
        >
          <Grid item>{title}</Grid>
        </Typography>
      )) ||
        null}
      {children}
    </>
  );
}

Step.propTypes = {
  progress: PropTypes.number.isRequired,
  title: PropTypes.string,
  previousStepPath: PropTypes.string,
  children: PropTypes.node,
};

export default Step;
