import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Actions from "components/Layout/Actions";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "@mui/material/Link";
import { getReturnUrl } from "utils/UrlUtil";
import Step from "components/Layout/Step";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";

function ServiceUnavailable() {
  const serviceName = useServiceName();

  const { t } = useTranslation();

  const title = t("serviceUnavailable:title");

  return (
    <Step progress={100}>
      <Illustration>
        <img src="/certification.png" alt={title} aria-hidden title={title} />
      </Illustration>
      <CenteredTitle>{title}</CenteredTitle>
      <MainContent>
        <Typography variant="body1" component="div" align="center">
          {t("serviceUnavailable:tryLater")}
          <br />
          {t("serviceUnavailable:apologize")}
        </Typography>
      </MainContent>
      <Actions>
        <Box mb={2} sx={{ width: "100%" }}>
          <Link
            sx={{ width: "100%" }}
            href={getReturnUrl({ error: true })}
            underline="none"
          >
            <Button>
              {t("common:goTo")} {serviceName}
            </Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
}

export default ServiceUnavailable;
