import React from "react";
import PropTypes from "prop-types";
import useTheme from "@mui/material/styles/useTheme";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Loader(props) {
  const {
    title = "",
    className = "",
    isLoading = false,
    children = null,
    ...others
  } = props;

  const theme = useTheme();
  return (
    <>
      {isLoading && (
        <Box
          sx={{
            flex: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            "& .MuiCircularProgress-circle": {
              stroke: theme.palette.primary.main,
              strokeLinecap: "round",
            },
            "& > span": {
              color: "#666666",
              marginTop: "16px",
            },
          }}
          className={className}
          {...others}
        >
          <CircularProgress />
          <span>{title}</span>
        </Box>
      )}
      {!isLoading && children}
    </>
  );
}

Loader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default Loader;
