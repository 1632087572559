import React from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "theme";
import { BrowserRouter } from "react-router-dom";
import initResponseInterceptor from "utils/InterceptorsUtil";
import { I18nextProvider } from "react-i18next";
import i18n from "config/i18n";
import Router from "./Router";

function App() {
  initResponseInterceptor();

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter basename={`${process.env.REACT_APP_BASENAME}`}>
          <Router />
        </BrowserRouter>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
