import React, { useCallback, useState, useEffect } from "react";
import useTheme from "@mui/material/styles/useTheme";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import MessageContext from "components/MessageContext";
import Box from "@mui/material/Box";
import Toaster, { types as ToasterTypes } from "components/Toaster";
import { useLocation } from "react-router";
import Footer from "./Footer";

function Layout(props) {
  const { children } = props;

  const theme = useTheme();

  const [footerSize, setFooterSize] = useState({});

  const location = useLocation();

  const [message, setMessage] = useState(null);
  const [footerRef, setFooterRef] = useState(null);

  const dispatchMessage = useCallback(
    (type) => (content) => {
      setMessage(
        content && {
          type,
          content,
          id: uuidv4(),
        },
      );
    },
    [],
  );

  const handleCloseMessage = () => {
    setMessage(null);
  };

  const handleResize = useCallback(() => {
    if (footerRef !== null) {
      const size = footerRef.getBoundingClientRect();
      setFooterSize({
        height: size.height,
        width: size.width,
      });
    }
  }, [footerRef]);

  // side effects at each navigation
  React.useEffect(() => {
    handleResize();
    setMessage(null);
  }, [location, handleResize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: `calc(100vh - ${footerSize.height}px)`,
          [theme.breakpoints.up("lg")]: {
            background: `url("/background.jpg") center center no-repeat fixed`,
            backgroundSize: "cover",
            justifyContent: "space-between",
            margin: 0,
            padding: 0,
            outline: 0,
          },
          "& ul": {
            listStylePosition: "initial",
            paddingInline: theme.spacing(3),
            "& li:not(:last-child)": {
              paddingBottom: theme.spacing(1.5),
            },
          },
        }}
      >
        <Box
          sx={{
            flex: "1 auto",
            display: "flex",
            [theme.breakpoints.up("lg")]: {
              justifyContent: "flex-end",
              alignItems: "start",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              flex: "1 auto",
              borderTop: `6px solid ${theme.palette.primary.main}`,
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.palette["background-light"],
              [theme.breakpoints.up("sm")]: {
                width: "414px",
              },
              [theme.breakpoints.up("md")]: {
                width: "530px",
              },
              [theme.breakpoints.up("lg")]: {
                flex: "0 auto",
                marginTop: "60px",
                marginRight: "100px",
                width: "320px",
                marginBottom: "50px",
              },
              [theme.breakpoints.up("xl")]: {
                marginTop: "80px",
                width: "414px",
                marginRight: "120px",
                marginBottom: "50px",
              },
            }}
          >
            {message && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1,
                }}
              >
                <Toaster type={message.type} onClose={handleCloseMessage}>
                  {message.content}
                </Toaster>
              </Box>
            )}
            <Box
              role="main"
              sx={{
                flex: "1 auto",
                padding: "0px 16px",
                display: "flex",
                flexDirection: "column",
                maxHeight: "100%",
                overflow: "auto",
                [theme.breakpoints.up("md")]: {
                  padding: "0px calc((100vw - 530px) / 2)",
                },
                [theme.breakpoints.up("lg")]: {
                  padding: "0px 16px",
                  overflow: "hidden",
                  minHeight: "calc(70vh)",
                },
              }}
            >
              <Box sx={{ marginTop: "10px", marginBottom: "13px" }}>
                <img
                  style={{ height: "34px", width: "88px" }}
                  src="/logo.svg"
                  alt="Logo cotepro"
                  title="Logo cotepro"
                />
              </Box>
              <MessageContext.Provider
                // eslint-disable-next-line react/jsx-no-constructed-context-values
                value={{
                  dispatchError: dispatchMessage(ToasterTypes.ERROR),
                  dispatchWarning: dispatchMessage(ToasterTypes.WARNING),
                  dispatchSuccess: dispatchMessage(ToasterTypes.SUCCESS),
                }}
              >
                <Box
                  sx={{
                    flex: "1 auto",
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "32px",
                    [theme.breakpoints.up("sm")]: {
                      paddingBottom: "40px",
                    },
                    [theme.breakpoints.up("md")]: {
                      paddingBottom: "24px",
                    },
                  }}
                >
                  {children}
                </Box>
              </MessageContext.Provider>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer onRefChange={(newRef) => setFooterRef(newRef)} />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
