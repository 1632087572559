import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function CenteredTitle(props) {
  const { children = null, ...others } = props;

  return (
    <Box mb={3} component={Typography} variant="h2" align="center" {...others}>
      {children}
    </Box>
  );
}

CenteredTitle.propTypes = {
  children: PropTypes.node,
};

export default CenteredTitle;
