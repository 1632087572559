import React from "react";
import PropTypes from "prop-types";
import useTheme from "@mui/material/styles/useTheme";

function Actions(props) {
  const { children = null, className = null, ...others } = props;

  const theme = useTheme();

  return (
    <div
      style={{
        marginTop: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      className={className}
      {...others}
    >
      {children}
    </div>
  );
}

Actions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Actions;
