import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M 12 1 C 18.066406 1 23 5.933594 23 12 C 23 18.066406 18.066406 23 12 23 C 5.933594 23 1 18.066406 1 12 C 1 5.933594 5.933594 1 12 1 Z M 12 3 C 7.035156 3 3 7.039062 3 12 C 3 16.960938 7.035156 21 12 21 C 16.964844 21 21 16.960938 21 12 C 21 7.039062 16.964844 3 12 3 Z M 13 10 L 13 18 L 11 18 L 11 10 Z M 12 6 C 12.554688 6 13 6.449219 13 7 C 13 7.550781 12.554688 8 12 8 C 11.445312 8 11 7.550781 11 7 C 11 6.449219 11.445312 6 12 6 Z M 12 6 "
      />
    </SvgIcon>
  );
}

export default Icon;
