import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 12 0 C 18.617188 0 24 5.382812 24 12 C 24 18.617188 18.617188 24 12 24 C 5.382812 24 0 18.617188 0 12 C 0 5.382812 5.382812 0 12 0 Z M 12 1.332031 C 6.117188 1.332031 1.332031 6.117188 1.332031 12 C 1.332031 17.882812 6.117188 22.667969 12 22.667969 C 17.882812 22.667969 22.667969 17.882812 22.667969 12 C 22.667969 6.117188 17.882812 1.332031 12 1.332031 Z M 15.527344 7.472656 C 15.789062 7.210938 16.210938 7.210938 16.472656 7.472656 C 16.730469 7.730469 16.730469 8.152344 16.472656 8.414062 L 12.941406 11.941406 L 16.472656 15.472656 C 16.730469 15.730469 16.730469 16.152344 16.472656 16.414062 C 16.339844 16.542969 16.171875 16.609375 16 16.609375 C 15.828125 16.609375 15.65625 16.542969 15.527344 16.414062 L 12 12.886719 L 8.472656 16.414062 C 8.210938 16.675781 7.789062 16.675781 7.527344 16.414062 C 7.269531 16.152344 7.269531 15.730469 7.527344 15.472656 L 11.058594 11.941406 L 7.527344 8.414062 C 7.269531 8.152344 7.269531 7.730469 7.527344 7.472656 C 7.789062 7.210938 8.210938 7.210938 8.472656 7.472656 L 12 11 Z M 15.527344 7.472656 "
      />
    </SvgIcon>
  );
}

export default Icon;
